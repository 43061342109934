//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var MSGINIT = '发送验证码';
// const MSGERROR = '验证码发送失败'
var MSGSCUCCESS = '${time}秒后重发';
var MSGTIME = 60;
import { isvalidatemobile } from '@/utils/validate';
export default {
  name: 'codelogin',
  data: function data() {
    var validatePhone = function validatePhone(rule, value, callback) {
      if (isvalidatemobile(value)[0]) {
        callback(new Error(isvalidatemobile(value)[1]));
      } else {
        callback();
      }
    };
    var validateCode = function validateCode(rule, value, callback) {
      if (value.length !== 4) {
        callback(new Error('请输入4位数的验证码'));
      } else {
        callback();
      }
    };
    return {
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      loginForm: {
        phone: '17547400800',
        code: ''
      },
      loginRules: {
        phone: [{
          required: true,
          trigger: 'blur',
          validator: validatePhone
        }],
        code: [{
          required: true,
          trigger: 'blur',
          validator: validateCode
        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: {},
  props: [],
  methods: {
    handleSend: function handleSend() {
      var _this = this;
      if (this.msgKey) return;
      this.msgText = MSGSCUCCESS.replace('${time}', this.msgTime);
      this.msgKey = true;
      var time = setInterval(function () {
        _this.msgTime--;
        _this.msgText = MSGSCUCCESS.replace('${time}', _this.msgTime);
        if (_this.msgTime === 0) {
          _this.msgTime = MSGTIME;
          _this.msgText = MSGINIT;
          _this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        localStorage.setItem("activeIndex", '0');
        if (valid) {
          _this2.$store.dispatch('Login', _this2.loginForm).then(function (res) {
            _this2.$router.push({
              path: '/'
            });
          });
        }
      });
    }
  }
};