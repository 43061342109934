//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sendmsg } from '@/api/login';
import { getStatusData } from '@/api/ceshi_xiugai/order';
// import { isvalidUsername } from '@/utils/validate'
export default {
  name: 'userlogin',
  data: function data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (!isvalidUsername(value)) {
    //     callback(new Error('请输入正确的用户名'))
    //   } else {
    //     callback()
    //   }
    // }
    // const validateCode = (rule, value, callback) => {
    //   if (this.code.value !== value) {
    //     this.loginForm.code = ''
    //     this.refreshCode()
    //     callback(new Error('请输入正确的验证码'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      loginForm: {
        username: '',
        password: ''
      },
      checked: false,
      code: {
        src: '',
        value: '',
        len: 4,
        type: 'text'
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 5,
          message: '密码长度最少为6位',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }, {
          min: 4,
          max: 4,
          message: '验证码长度为4位',
          trigger: 'blur'
        }, {
          required: true,
          trigger: 'blur'
        }]
      },
      passwordType: 'password'
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: {
    userId: function userId() {
      return this.$store.state.user.id;
    }
  },
  props: [],
  methods: {
    showPassword: function showPassword() {
      this.passwordType === '' ? this.passwordType = 'password' : this.passwordType = '';
    },
    handleLogin: function handleLogin() {
      var _this = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this.$store.dispatch('Login', _this.loginForm).then(function (res) {
            // 登录用户组织架构中，人员职级不为空的用户，登录系统后默认展示个人统计，其他用户不变
            if (res.info.professional_level != '') {
              _this.$router.push({
                path: '/homePage/personalAnalysis'
              });
            } else {
              _this.$router.push({
                path: '/'
              });
            }
            _this.$store.dispatch('activeIndex', 0);
            // localStorage.setItem('activeIndex', 0);
            // 调用获取未读数量的接口
            getStatusData().then(function (res) {
              _this.$store.dispatch('unreadMessage', res.data);
              localStorage.setItem('unreadMessage', JSON.stringify(res.data));
            });
          });
        }
      });
    }
  }
};